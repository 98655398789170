// Links

// Color
$link--color: (
	link: 								get-color(dark, 1),
	link-hover: 						null,
	header-link: 						get-color(dark, 2),
	header-link-hover: 					get-color(dark, 1),
	header-link-mobile: 				get-color(dark, 1),
	header-link-mobile-hover: 			null,
	footer-link: 						get-color(dark, 2),
	footer-link-hover: 					get-color(dark, 1),
	footer-block-link: 					get-color(dark, 2),
	footer-block-link-hover: 			get-color(dark, 1),
	button-link: 						get-color(dark, 2),
	button-link-hover: 					get-color(dark, 1),
	func-link: 							null,
	func-link-hover: 					null,	
	// ↓ Inverted colors
	link-inverse: 						get-color(light, 3),
	link-hover-inverse: 				null,
	header-link-inverse: 				get-color(dark, 3),
	header-link-hover-inverse:			get-color(primary, 1),
	footer-link-inverse: 				null,
	footer-link-hover-inverse: 			null,
	footer-block-link-inverse: 			null,
	footer-block-link-hover-inverse:	null,
	button-link-inverse: 				null,
	button-link-hover-inverse:			get-color(primary, 1),
	func-link-inverse: 					null,
	func-link-hover-inverse:			get-color(primary, 1)	
);

// Font-weight
$link--weight: (
	link: 				null,
	header-link:		500,
	footer-link:		null,
	footer-block-link:	null,
	button-link:		null
);

// Text decoration
$link-main--decoration: 				none;
$link-main--decoration-hover: 			null;
$link-header--decoration: 				null;
$link-header--decoration-hover:			null;
$link-footer--decoration: 				null;
$link-footer--decoration-hover: 		null;
$link-footer-block--decoration: 		null;
$link-footer-block--decoration-hover:	null;
$link-button--decoration: 				null;
$link-button--decoration-hover: 		null;
$link-func--decoration: 				null;
$link-func--decoration-hover: 			null;

// Text transform
$link-header--transform: 		null;
$link-footer--transform: 		null;
$link-footer-block--transform: 	null;
$link-button--transform: 		null;
$link-func--transform: 			null;

// Don't change lines below!
$color:			map-push($color, $link--color);
$font--weight: 	map-push($font--weight, $link--weight);